import React, { useState, useEffect } from "react";
// import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby";

import AgeCalculator from "./agegate-component-ar";
import Footer from "./footer/footerAr";
import LanguageSwitcher from "./header/languageSwitcherAr";

import snickersLogo from "../images/snickershungerinsuranceAr.png";

const AgeGate = (props) => {
  const [isOldError, setIsOldError] = useState(false);
  const [ageError, setAgeError] = useState(false);
  // useEffect(() => {
  //   document.body.className = "agegate";
  //   return () => {
  //     document.body.className = "";
  //   };
  // });
  useEffect(() => {
    document.querySelector("body").classList.add("agegate");
  }, []);
  return (
    <>
      <section className="agegate-hldr d-flex justify-content-between flex-column align-items-center pt-5">
        <div className="d-flex flex-column justify-content-center align-items-center h-100">
          <LanguageSwitcher />
          <Link to="/">
            <figure className="logo">
              {/* <StaticImage
                src="../images/snickers-hunger-insurance.png"
                alt="Snickers Logo"
                quality={100}
              /> */}
              <img
                src={snickersLogo}
                alt="Snickers Logo"
                className="img-fluid"
              />
            </figure>
          </Link>
          <h1 className="text-white text-uppercase">
            رجاءً تحديد العمر للاستفادة من التغطية
          </h1>
          <AgeCalculator
            setIsOldError={setIsOldError}
            setAgeError={setAgeError}
            setAgeGateShow={props.setAgeGateShow}
          />
          {isOldError && (
            <p className="text-center input-error mb-0">
              {/* You are not Eligible to Enter into Site! */}
              مممم.. أكمل إدخال تفاصيلك ثم إضغط على الدخول
            </p>
          )}
          {ageError && (
            <p className="text-center input-error mb-0">
              {/* You are not Eligible to Enter into Site! */}
              للأسف لا يحق لك دخول هذا الموقع
            </p>
          )}
        </div>
        <Footer />
      </section>
    </>
  );
};

export default AgeGate;

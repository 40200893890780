import * as React from "react";
import FooterNav from "./footerNavAr";
import Copyright from "./copyrightAr";

const Footer = () => (
  <>
    <footer className="main-footer">
      <div className="container">
        <FooterNav />
        <Copyright />
      </div>
    </footer>
  </>
);

export default Footer;

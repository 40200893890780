import React, { useEffect } from "react";
import { navigate } from "gatsby";
import Cookies from "universal-cookie";
import Layout from "../../components/layoutAr";
import Seo from "../../components/seoAr";
import Agegate from "../../components/agegateAr";

const AgeGate = (props) => {
  console.log(props);
  useEffect(() => {
    if (props.path.startsWith("/ar")) {
      document.querySelector("body").classList.add("rightToLeft");
    }
  }, []);
  let ageCookie = new Cookies();

  if (ageCookie.get("isOldEnough")) {
    navigate("/ar");
  }

  return (
    <Layout>
      <Seo title="بوّابة تحديد العمر | تأمين سنكرس ضدّ الجوع" lang="ar" />
      <Agegate />
    </Layout>
  );
};

export default React.memo(AgeGate);
